import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import blogimage from "../images/inspire_you.jpg"
import iconOrange from "../images/icon-orange.png"

const sustainability = () => (
  <Layout>
    <SEO title="Sustainability" />
    <div id="whyInspire" class="px-4 mt-2 mb-5 text-center">
      <h1 class="display-5 fw-bold">Sustainability in Global Mobility!</h1>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-4 text-start">
          Inspire works with Global Mobility Professionals who need to change
          their thinking, practices, and policies to deliver a positive
          sustainable future.
        </p>
        <p class="lead mb-4 text-start">
          Paul started the fast-growing Sustainability in Global Mobility group
          to ask questions, share knowledge and best practice of the actions
          Global Mobility Professionals and Service Providers to positively
          educate and influence, whilst encouraging collaboration.{" "}
        </p>
        <p class="lead mb-4 text-start">
          <b>Global Mobility &amp; Procurement:</b> There are simple and very
          effective solutions that can be adapted based upon your companies’
          culture and demographic of relocating employee which deliver the
          following results.
        </p>
        <ul class="lead text-start">
          <li>Reduce your programs carbon footprint.</li>
          <li>Increased employee engagement.</li>
          <li>Reduce cost.</li>
        </ul>
        <p class="lead text-start">
          Planning an RFP and need to ensure you work with sustainable
          providers? There are no industry standards but there are effective
          ways to ensure you evaluate and source a sustainable provider.
        </p>
        <p class="lead text-start">
          <b>Mobility Service Providers:</b> The Inspire approach is based on
          your individual needs. Understanding of your business model to help
          you evolve to a sustainable business.
        </p>
        <p class="lead text-start">
          <b>Objective:</b> Build a program based upon availability of resources
          that has meaning, is easily explained, backed by data and integral to
          your company.
        </p>
        <ul class="lead text-start">
          <li>Develop a roadmap &amp; timeline of actions and commitments.</li>
          <li>Save time, cut through endless evaluation and terminology.</li>
          <li>Help you justify your actions; no company is perfect!</li>
        </ul>
        <p class="lead">
          <strong>
            Contact Inspire if you would like to discuss sustainability
            solutions and how ‘morality’ will help you positively influence and
            educate!
          </strong>
        </p>
      </div>
    </div>
  </Layout>
)

export default sustainability
